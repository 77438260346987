var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Deskundige Gebruikersbeheer")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _vm.organizationsLoaded
              ? _c(
                  "div",
                  [
                    _c(
                      "v-tabs",
                      {
                        attrs: { "slider-color": "secondary" },
                        model: {
                          value: _vm.activeTab,
                          callback: function($$v) {
                            _vm.activeTab = $$v
                          },
                          expression: "activeTab"
                        }
                      },
                      _vm._l(_vm.organizations, function(organization) {
                        return _c(
                          "v-tab",
                          {
                            key: organization.id,
                            staticClass: "tab__filled",
                            class: {
                              active: _vm.activeTab === "tab-" + organization.id
                            },
                            attrs: { href: "#tab-" + organization.id }
                          },
                          [_vm._v(" " + _vm._s(organization.name) + " ")]
                        )
                      }),
                      1
                    ),
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.activeTab,
                          callback: function($$v) {
                            _vm.activeTab = $$v
                          },
                          expression: "activeTab"
                        }
                      },
                      _vm._l(_vm.organizations, function(organization) {
                        return _c(
                          "v-tab-item",
                          {
                            key: organization.id,
                            attrs: {
                              transition: false,
                              "reverse-transition": false,
                              lazy: true,
                              value: "tab-" + organization.id
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "elementPanel elementPanel--user"
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("DataTable", {
                                          ref:
                                            "`${organization.name}DataTable`",
                                          refInFor: true,
                                          attrs: {
                                            options: _vm.tableOptions,
                                            limit: 25,
                                            visibility: _vm.visibility
                                          },
                                          on: {
                                            "data-update": _vm.refreshDataTable
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }