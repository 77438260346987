import { Organization } from '@/models/Organization';
import { Component, Vue, Watch } from 'vue-property-decorator';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { User } from '@/models/User';

@Component<ExpertUsers>({})

// like the other user admin pages, but experts can be sourced from other databases
// as well. For this, there's the special 'dmz' endpoint. This is activated by the dmz function
export default class ExpertUsers extends Vue {
  public $pageTitle = 'Deskundige Gebruikersbeheer';

  public activeTab: any | null = null;

  protected user: User | null = null;

  protected organizations: Organization[] = [];

  protected organizationsLoaded = false;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  public mounted() {
    this.emitBreadcrumb();

    new Organization()
      .getAllExperts()
      .then((response: Organization[]) => {
        if (response.length > 0) {
          this.organizations = response;
          if (Object.prototype.hasOwnProperty.call(response[0], 'id')) {
            (this.activeTab as any) = `tab-${response[0].id}`;
          }
          this.organizationsLoaded = true;
        } else {
          throw new Error('no expert organisations loaded');
        }
      });
  }

  protected emitBreadcrumb() {
    let currentStatus = this.getCurrentStatus;
    if (! currentStatus || ! currentStatus.length) {
      currentStatus = 'Deskundige Gebruikersbeheer';
    }
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Deskundige Gebruikersbeheer' }],
    });
  }

  get tableOptions(): Object {
    return {
      model: new User().dmz(this.activeTab.replace('tab-', '')),
      name: {
        singular: 'Deskundige',
        plural: 'Deskundigen',
      },
      headers: [
        {
          text: 'Naam',
          value: 'name',
          action: `/expert-user/{id}?organization=${this.activeTab.replace('tab-', '')}`,
          sortable: {
            key: 'name',
            order: 'ASC',
          },
        },
        {
          text: 'Zakelijk email',
          value: 'business_email',
        },

        {
          text: 'Email',
          value: 'email',
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'remove_red_eye',
          action: `/expert-user/{id}?organization=${this.activeTab.replace('tab-', '')}`,
        },
      ],
      filters: [],
    };
  }

  get getCurrentStatus() {
    return this.$route.query.status;
  }

  protected refreshDataTable() {
    document.dispatchEvent(
      new CustomEvent('datatable:hook', {
        detail: (dataTable: DataTable) => {
          dataTable.refresh();
        },
      }),
    );
  }

  public redirect() {
    if (this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect.toString());
    } else {
      this.$router.push('/reports');
    }
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface TableFilter {
  status?: string;
  expert?: string;
  organization?: string;
  type?: string;
  types?: string[];
  tags?: string[];
}
